
import { mapGetters, mapActions, mapMutations } from "vuex"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { VALIDATION_ERROR, TABLE_NAMES, FIELD_TYPES, MAX_CHARACTER_LIMIT, DATA_RETENTION_OPTIONS } from "@/constants"
import ThemisInput from "@/components/shared/input"

export default {
  name      : "Fields",
  components: { ThemisInput },
  data() {
    return {
      showAddFieldDialog: false,
      field             : {
        systemName        : null,
        label             : null,
        optionListId      : null,
        type              : null,
        widget            : null,
        dataRetentionValue: null
      },
      fieldLabelCharacterLimit     : MAX_CHARACTER_LIMIT.FIELD_LABEL,
      fieldSystemNameCharacterLimit: MAX_CHARACTER_LIMIT.FIELD_SYSTEM_NAME,
      fieldTypes                   : Object.values(FIELD_TYPES)
    }
  },
  methods: {
    getClassForFieldRow(item) {
      if (item.id === this.selectedField?.id) {
        return "blue lighten-5"
      }
    },
    ...mapActions({
      addFieldV2: "fields/addFieldV2",
      notify    : "shared/notify"
    }),
    ...mapMutations({
      setFieldV2AddError: "fields/setFieldV2AddError"
    }),
    resetFieldAdd() {
      this.showAddFieldDialog       = false
      this.field.systemName         = null
      this.field.label              = null
      this.field.dataRetentionValue = null
      this.field.optionListId       = null
      this.field.type               = null
      this.field.widget             = null
    },
    handleClickOnRow(item) {
      if((this.$route.name === "fields" || this.$route.name === "field") &&
      +this.$route.params?.id !== item.id) {
        this.$router.push({ name: "field", params: { id: item.id } })
      }
    },
    getFieldTypeAlert(fieldType) {
      let alertToDisplay
      if (fieldType === FIELD_TYPES.LONG_TEXT.value) {
        alertToDisplay = this.$t(this.fieldTypes.find(type => type.value === fieldType)?.name)
        return this.$t("1219", { name: alertToDisplay })
      } else {
        alertToDisplay = this.$tc(this.fieldTypes.find(type => type.value === fieldType)?.name)
        return this.$t("1215", { name: alertToDisplay })
      }
    },
    shouldDisplayOptionSelect(type) {
      return ["option list", "multiple option list"].includes(type)
    }
  },
  computed: {
    ...mapGetters({
      fieldsV2                : "fields/fieldsV2",
      isLoadingFields         : "fields/isLoadingFieldsV2",
      isAddingFieldV2         : "fields/isAddingFieldV2",
      isFieldV2Added          : "fields/isFieldV2Added",
      fieldV2AddError         : "fields/fieldV2AddError",
      optionLists             : "optionLists/optionLists",
      isAiAnonymisationEnabled: "configurations/isAiAnonymisationEnabled"
    }),
    optionListsMap() {
      const optionListsMap = new Object()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    headersForTable() {
      return getHeadersForTable(TABLE_NAMES.FIELDS, this.$t.bind(this))
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.FIELDS, this.$t.bind(this))
    },
    fieldsForTable() {
      return this.fieldsV2.map(field => {
        const fieldType = Object.values(FIELD_TYPES).find(type => type.value === field.type)
        return {
          id        : field.id,
          systemName: field.systemName,
          fieldType : this.$t(fieldType.name, {
            name: this.optionListsMap[field.optionListId] ? `: ${this.optionListsMap[field.optionListId].name}` : ""
          }),
          fieldTypeIcon: fieldType.icon
        }
      })
    },
    fieldSystemName() {
      return this.field.systemName
    },
    fieldType() {
      return this.field.type
    },
    noValidFieldInputData() {
      return !this.field.systemName
        || !this.field.label
        || !this.fieldType
        || ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(this.fieldType)
            && !this.field.optionListId)
        || this.field.label.length > this.fieldLabelCharacterLimit
        || this.field.systemName.length > this.fieldSystemNameCharacterLimit
    },
    isFieldSystemNameDuplicate() {
      if(this.fieldV2AddError) {
        return this.fieldV2AddError.field === VALIDATION_ERROR.FIELD.SYSTEM_NAME &&
          this.fieldV2AddError.type === VALIDATION_ERROR.TYPE.DUPLICATE
      }
    },
    dataRetentionOptions() {
      const dataRetentionOptions = [...DATA_RETENTION_OPTIONS]
      if (this.isAiAnonymisationEnabled && this.isFieldTypeLongTextOrShortText) {
        dataRetentionOptions.push(this.$t("1175"))
      }
      return dataRetentionOptions
    },
    isFieldTypeLongTextOrShortText() {
      return this.fieldType === FIELD_TYPES.LONG_TEXT.value || this.fieldType === FIELD_TYPES.SHORT_TEXT.value
    }
  },
  watch: {
    fieldSystemName: {
      handler: function() {
        this.setFieldV2AddError(undefined)
      }
    },
    fieldType: {
      handler: function(value) {
        if (value) {
          const isFieldTypeOptionList = value === FIELD_TYPES.OPTION_LIST.value
          if (!isFieldTypeOptionList) {
            this.field.optionListId = null
          }
          this.field.widget = this.fieldTypes.find(fieldType => fieldType.value === value)?.widget
        }
      }
    },
    isFieldV2Added: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "788",
            parameters: {
              systemName: this.field.systemName
            }
          })
          this.resetFieldAdd()
        }
      }
    }
  }
}