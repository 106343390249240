var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"data-cy":"1602","fluid":""}},[_c('v-row',{attrs:{"data-cy":"3210"}},[_c('v-col',{attrs:{"data-cy":"3211"}},[_c('div',{staticClass:"d-block mb-4",attrs:{"data-cy":"3212"}},[_c('span',{attrs:{"data-cy":"3213"}},[_vm._v(_vm._s(_vm.$t("1046")))]),_c('span',{ref:"link_issue_forms",staticClass:"secondary--text link",attrs:{"data-cy":"3214"},on:{"click":function($event){return _vm.$router.push({ name: 'issue-forms' })}}},[_vm._v(_vm._s(_vm.$t("1043")))]),_c('span',{attrs:{"data-cy":"3215"}},[_vm._v(" / ")]),_c('span',{ref:"link_reporter_intake_forms",staticClass:"secondary--text link",attrs:{"data-cy":"3216"},on:{"click":function($event){return _vm.$router.push({ name: 'reporter-intake-forms' })}}},[_vm._v(_vm._s(_vm.$t("1044")))]),_c('span',{attrs:{"data-cy":"3217"}},[_vm._v(" / ")]),_c('span',{ref:"link_issue_fields",staticClass:"secondary--text link",attrs:{"data-cy":"3218"},on:{"click":function($event){return _vm.$router.push({ name: 'issue-fields' })}}},[_vm._v(_vm._s(_vm.$t("1045")))])]),_c('v-row',{attrs:{"data-cy":"1603"}},[_c('v-col',{attrs:{"data-cy":"1604"}},[_c('v-btn',{ref:"button_add_new_field",staticClass:"float-right",attrs:{"data-cy":"1670","color":"primary"},on:{"click":function($event){_vm.showAddFieldDialog = true}}},[_c('v-icon',{attrs:{"data-cy":"1671","left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t("790")))],1)],1)],1),_c('v-data-table',{ref:"table_fields",staticClass:"elevation-1 mt-4",attrs:{"data-cy":"1605","item-key":"id","items":_vm.fieldsForTable,"headers":_vm.headersForTable,"loading":_vm.isLoadingFields,"footers-props":_vm.footersForTable,"item-class":_vm.getClassForFieldRow,"sort-by":"id","items-per-page":_vm.$TABLES.FIELDS.itemsPerPage,"sort-desc":"","multi-sort":""},on:{"click:row":function (item) { return _vm.handleClickOnRow(item); }},scopedSlots:_vm._u([{key:"item.fieldType",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"data-cy":"1606"}},[_vm._v(_vm._s(item.fieldTypeIcon))]),_c('span',{staticClass:"info--text",attrs:{"data-cy":"1607"}},[_vm._v(_vm._s(item.fieldType))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-block float-right",attrs:{"data-cy":"2050"}},[_c('v-icon',{staticClass:"pr-3",attrs:{"data-cy":"2051","color":"white","small":""}},[_vm._v("mdi-chevron-right")]),_c('v-btn',{attrs:{"data-cy":"2052","icon":""}},[_c('v-icon',{attrs:{"data-cy":"2053"}},[_vm._v("mdi-pencil-outline")])],1)],1)]}}])}),_c('v-dialog',{attrs:{"data-cy":"1672","value":_vm.showAddFieldDialog,"width":"577","persistent":""}},[_c('validation-observer',{attrs:{"data-cy":"1673"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"1674"},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.addFieldV2(_vm.field); })}}},[_c('v-card',{staticClass:"pa-2",attrs:{"data-cy":"1675"}},[_c('v-card-title',{staticClass:"info--text mb-2 h5",attrs:{"data-cy":"1676"}},[_vm._v(_vm._s(_vm.$t("776")))]),_c('v-card-subtitle',{staticClass:"mb-3 black--text body-1",attrs:{"data-cy":"1677"}},[_vm._v(_vm._s(_vm.$t("777")))]),_c('v-card-text',{attrs:{"data-cy":"1678"}},[_c('themis-input',{attrs:{"data-cy":"1679","p-rules":("duplicate:" + _vm.isFieldSystemNameDuplicate + "|max:" + _vm.fieldSystemNameCharacterLimit),"p-label":_vm._f("required")(("" + (_vm.$t('778')))),"p-label-class":"secondary--text","p-name":"778"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-text-field',{ref:"input_system_name",attrs:{"data-cy":"1680","color":"secondary","placeholder":_vm.$t('779'),"disabled":_vm.isAddingFieldV2,"error-messages":errors,"outlined":"","persistent-placeholder":"","clearable":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.field.systemName),callback:function ($$v) {_vm.$set(_vm.field, "systemName", $$v)},expression:"field.systemName"}})]}}],null,true)}),_c('themis-input',{attrs:{"data-cy":"2716","p-name":"780","p-label":_vm._f("required")(_vm.$t('780')),"p-rules":("max:" + _vm.fieldLabelCharacterLimit),"p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-text-field',{ref:"input_label",attrs:{"data-cy":"1681","color":"secondary","placeholder":_vm.$t('781'),"disabled":_vm.isAddingFieldV2,"error-messages":errors,"outlined":"","persistent-placeholder":"","clearable":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.field.label),callback:function ($$v) {_vm.$set(_vm.field, "label", $$v)},expression:"field.label"}})]}}],null,true)}),_c('v-divider',{staticClass:"mb-8"}),_c('themis-input',{attrs:{"data-cy":"","p-name":"782","p-label":_vm._f("required")(_vm.$t('782')),"p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
return [_c('v-select',{ref:"select_field_type",attrs:{"data-cy":"1682","items":_vm.fieldTypes,"item-text":"name","item-value":"value","placeholder":_vm.$t('963'),"outlined":"","persistent-placeholder":""},on:{"focus":onFocus,"blur":onBlur},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-icon',{attrs:{"data-cy":"2707"}},[_c('v-icon',{staticClass:"mr-6",attrs:{"data-cy":"2708","left":""}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',{attrs:{"data-cy":"2709"}},[_c('v-list-item-title',{attrs:{"data-cy":"2710"}},[_vm._v(_vm._s(_vm.$tc(item.name)))]),(item.value === 'long text')?_c('div',{staticClass:"d-block",attrs:{"data-cy":"2711"}},[_c('div',{staticClass:"info--text body-2",attrs:{"data-cy":"2712"}},[_vm._v(_vm._s(_vm.$t('964')))])]):_vm._e(),(item.value === 'short text')?_c('div',{staticClass:"d-block",attrs:{"data-cy":"2350"}},[_c('div',{staticClass:"info--text body-2",attrs:{"data-cy":"2351"}},[_vm._v(_vm._s(_vm.$t('799')))])]):_vm._e(),(item.value === 'option list')?_c('div',{staticClass:"d-block",attrs:{"data-cy":"3008"}},[_c('div',{staticClass:"info--text body-2",attrs:{"data-cy":"3009"}},[_vm._v(_vm._s(_vm.$t('496')))])]):_vm._e(),(item.value === 'multiple option list')?_c('div',{staticClass:"d-block",attrs:{"data-cy":"3101"}},[_c('div',{staticClass:"info--text body-2",attrs:{"data-cy":"3102"}},[_vm._v(_vm._s(_vm.$t('1073')))])]):_vm._e()],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"data-cy":"2713","left":""}},[_vm._v(_vm._s(item.icon))]),_c('span',{attrs:{"data-cy":"2714"}},[_vm._v(_vm._s(_vm.$tc(item.name)))])]}}],null,true),model:{value:(_vm.field.type),callback:function ($$v) {_vm.$set(_vm.field, "type", $$v)},expression:"field.type"}})]}}],null,true)}),(_vm.field.type && _vm.field.type !== 'option list')?_c('v-alert',{ref:"alert_field_usage",staticClass:"mt-n5 primary--text",attrs:{"data-cy":"2715","color":"primary","type":"info","dense":"","outlined":"","text":""}},[_c('span',{attrs:{"data-cy":"2743"}},[_vm._v(_vm._s(_vm.getFieldTypeAlert(_vm.field.type)))])]):_vm._e(),(_vm.shouldDisplayOptionSelect(_vm.field.type))?_c('themis-input',{attrs:{"data-cy":"3100","p-name":"784","p-label":_vm._f("required")(_vm.$t('784')),"p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
return [_c('v-select',{ref:"select_option_list",attrs:{"data-cy":"1683","items":_vm.optionLists,"item-text":"name","item-value":"id","disabled":_vm.isAddingFieldV2,"placeholder":_vm.$t('785'),"outlined":"","persistent-placeholder":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.field.optionListId),callback:function ($$v) {_vm.$set(_vm.field, "optionListId", $$v)},expression:"field.optionListId"}})]}}],null,true)}):_vm._e(),_c('themis-input',{attrs:{"data-cy":"3371","p-name":"1083","p-label":_vm.$t('1083'),"p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"append-label",fn:function(ref){return [_c('v-tooltip',{ref:"data_retention_tooltip",attrs:{"data-cy":"3372","max-width":"400","color":"grey darken-2","nudge-right":"180","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({ref:"information_icon",staticClass:"ml-1",attrs:{"data-cy":"3373","color":"grey","small":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[(_vm.isAiAnonymisationEnabled && _vm.isFieldTypeLongTextOrShortText)?_c('div',{staticClass:"d-block"},[_c('span',{attrs:{"data-cy":"3374"}},[_vm._v(_vm._s(_vm.$t('1085')))]),_c('br'),_c('br'),_c('span',{attrs:{"data-cy":"3375"}},[_vm._v(_vm._s(_vm.$t('1086')))])]):_c('div',{staticClass:"d-block"},[_c('span',{attrs:{"data-cy":"3376"}},[_vm._v(_vm._s(_vm.$t('1087')))])])])]}},{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
return [_c('v-select',{ref:"select_data_retention_option",staticClass:"mb-1",attrs:{"data-cy":"3372","items":_vm.dataRetentionOptions,"item-text":"name","item-value":"value","placeholder":_vm.$t('1084'),"outlined":"","persistent-placeholder":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.field.dataRetentionValue),callback:function ($$v) {_vm.$set(_vm.field, "dataRetentionValue", $$v)},expression:"field.dataRetentionValue"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"mx-2 mb-2 justify-end",attrs:{"data-cy":"1684"}},[_c('v-btn',{ref:"button_cancel",staticClass:"mr-6",attrs:{"data-cy":"1685","disabled":_vm.isAddingFieldV2,"text":""},on:{"click":_vm.resetFieldAdd}},[_vm._v(_vm._s(_vm.$t("786")))]),_c('v-btn',{ref:"button_add",attrs:{"data-cy":"1686","color":"primary","type":"submit","disabled":_vm.noValidFieldInputData,"loading":_vm.isAddingFieldV2}},[_vm._v(_vm._s(_vm.$t("787")))])],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }